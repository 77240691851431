// <!-- UTILITIES -->
import { getSupportedTimeZones, getLegacyTimezones } from '@/utils/timezone';
import { Option } from '@/utils/options';

// <!-- CONSTANTS -->
const SUPPORTED_TIMEZONES = getSupportedTimeZones();
const LEGACY_TIMEZONES = getLegacyTimezones();

// <!-- TYPES -->
/**
 * @typedef {Option<TimeZone.Identifier | TimeZone.LegacyIdentifier | 'Select Timezone', TimeZone.Identifier | TimeZone.LegacyIdentifier | 'placeholder'>} TimeZoneOption
 */

// <!-- COMPOSABLES -->

/**
 * Compose timezone options for a select input dialog.
 * @returns {TimeZoneOption[]}
 */
export const useTimezoneOptions = () => {
    /** @type {TimeZoneOption} */
    const prompt = new Option({
        label: 'Select Timezone',
        value: 'placeholder',
        attrs: {
            disabled: true,
        },
    });
    /** @type {TimeZoneOption[]} */
    const validOptions = SUPPORTED_TIMEZONES.map(
        (timezone) =>
            new Option({
                label: timezone,
                value: timezone,
            })
    );
    /** @type {TimeZoneOption[]} */
    const legacyOptions = LEGACY_TIMEZONES.map(
        (timezone) =>
            new Option({
                label: timezone,
                value: timezone,
                attrs: { disabled: true },
            })
    );
    return [prompt, ...validOptions, ...legacyOptions];
};

/**
 * Return the value for a given timezone if it's valid.
 * @param {any} value Timezone.
 * @returns {TimeZone.Identifier | TimeZone.LegacyIdentifier | '' | 'placeholder'} Timezone validated.
 */
export const useTimezoneIfValid = (value) => {
    if (SUPPORTED_TIMEZONES.includes(value)) {
        return /** @type {TimeZone.Identifier} */ (value);
    }

    if (LEGACY_TIMEZONES.includes(value)) {
        return /** @type {TimeZone.LegacyIdentifier} */ (value);
    }

    // value is placeholder.
    return 'placeholder';
};

// <!-- EXPORTS -->
export default {
    Timezones: [...SUPPORTED_TIMEZONES],
    useTimezoneIfValid,
    useTimezoneOptions,
};
