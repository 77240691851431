// Composable used for managing Location form state.

// <!-- API -->
import { ref, computed } from 'vue';
import { useECNBCache, ECNBCache } from '@/hooks/store/useECNBCache';
import { useRoute } from 'vue-router';
import { LocationDetails } from '~DataManager/hooks/useLocationDetails';
import locations from '@/api/v1/accounts/locations';

// <!-- TYPES -->
/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
/** @typedef {globalThis.Account.Model} AccountResource */
/** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */
/** @typedef {import('@formkit/core').FormKitNode} FormKitNode */

// <!-- COMPOSABLES -->
import { ComposableConfig, ComposableModule } from '@/hooks/useComposable';
import { useTimezoneOptions } from '@/features/data-manager/hooks/useTimezoneOptions';

// <!-- UTILITIES -->
import compare from 'just-compare';
import omit from 'just-omit';
import difference from 'lodash-es/difference';
import { diff, jsonPatchPathConverter } from 'just-diff';
import { collect } from 'collect.js';

// <!-- CLASSES -->

/**
 * @class
 * Location form expected props.
 */
export class LocationFormProps {
    /**
     * Save props to this object and give them expected types.
     * @param {Record<String, any>} props
     */
    constructor(props) {
        /** @type {(config: LocationFormConfig) => Promise<any>} Async callback to execute once the form has initialized. Accepts the configuration object as a parameter. */
        this.onInit = props?.onInit ?? (async (_) => {});

        /** @type {() => Promise<any>} Async callback to execute when reseting the form state. */
        this.onReset = props?.onReset ?? (async () => {});

        /** @type {() => Promise<any>} Async callback to execute when exiting the form. */
        this.onExit = props?.onExit ?? (async () => {});

        /** @type {() => Promise<any>} Async callback to execute when cancelling the form. */
        this.onCancel = props?.onCancel ?? (async () => {});

        /** @type {() => Promise<any>} Async callback to execute when refreshing the current location data. */
        this.onRefresh = props?.onRefresh ?? (async () => {});

        /** @type {() => Promise<any>} Async callback to execute when beginning the specified operation. */
        this.onShowEdit = props?.onShowEdit ?? (async () => {});

        /** @type {() => Promise<any>} Async callback to excecute when cancelling the specified operation. */
        this.onEditCancel = props?.onEditCancel ?? (async () => {});

        /** @type {() => Promise<any>} Async callback to execute when saving the form. */
        this.onSave = props?.onSave ?? (async () => {});

        /** @type {() => Promise<any>} Async callback to execute when beginning the specified operation. */
        this.onShowAdd = props?.onShowAdd ?? (async () => {});

        /** @type {() => Promise<any>} Async callback to excecute when cancelling the specified operation. */
        this.onAddCancel = props?.onAddCancel ?? (async () => {});

        /** @type {() => Promise<any>} Async callback to execute when creating a new location. */
        this.onCreate = props?.onCreate ?? (async () => {});

        /** @type {() => Promise<any>} Async callback to execute when beginning the specified operation. */
        this.onShowConfirmDelete =
            props?.onShowConfirmDelete ?? (async () => {});

        /** @type {() => Promise<any>} Async callback to excecute when cancelling the specified operation. */
        this.onConfirmDeleteCancel =
            props?.onConfirmDeleteCancel ?? (async () => {});

        /** @type {() => Promise<any>} Async callback to execute when deleting a location. */
        this.onDelete = props?.onDelete ?? (async () => {});

        /** @type {Vue.Ref<{ id: Number, name?: String }>} External target location pointer to use. */
        this.targetLocation = computed(() => {
            const target = props?.targetLocation?.value ?? null;
            const hasProp = !!target;
            const hasValidId = hasProp && target?.id != null && target.id >= 0;
            return hasProp && hasValidId ? target : null;
        });
    }
}

/**
 * @class
 * Location form configuration.
 * @template {Record<String, any>} [Props=any]
 * @template {Vue.EmitsOptions} [E=any]
 * @extends {ComposableConfig<LocationFormProps, E, LocationFormConstants, LocationFormState, LocationFormProperties, LocationFormHandlers, LocationFormMethods>}
 */
export class LocationFormConfig extends ComposableConfig {
    /**
     * Construct the configuration object.
     * @param {Props} props
     * @param {Vue.SetupContext<E>} config
     */
    constructor(props, config) {
        super(new LocationFormProps(props), config);
        /** @type {ECNBCache} */
        this.cache = useECNBCache();
        /** @type {Router.RouteLocationNormalizedLoaded} Loaded route. */
        this.route = useRoute();
    }

    /**
     * Initialize the composable.
     * @returns {Promise<this>}
     */
    async initializeComposable() {
        this.isInitialized = false;
        // Order can be different for other composables.
        this.initializeConstants()
            .initializeState()
            .initializeProperties()
            .initializeMethods()
            .initializeHandlers();
        await this.handlers.onInit();
        this.isInitialized = true;
        return this;
    }

    initializeConstants() {
        new LocationFormConstants(this);
        return this;
    }

    initializeState() {
        new LocationFormState(this);
        return this;
    }

    initializeProperties() {
        new LocationFormProperties(this);
        return this;
    }

    initializeHandlers() {
        new LocationFormHandlers(this);
        return this;
    }

    initializeMethods() {
        new LocationFormMethods(this);
        return this;
    }
}

/**
 * @class
 * Local form constants.
 * @extends {ComposableModule<LocationFormConfig>}
 */
export class LocationFormConstants extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {LocationFormConfig} config Input configuration.
     */
    constructor(config) {
        super('constants', config);
        const { route } = config;

        /** Current route location. @type {{ id: Number }} */
        this.routeLocation = { id: Number(route.params.id) };

        /** Timezone options. @type {TimezoneOption[]} */
        this.timezoneOptions = useTimezoneOptions();

        /** Form sections. @type {Record<String, ({ type: 'text', label: String, name: String })[]>} */
        this.sections = {
            dataLogger: [
                // {
                //     type: 'text',
                //     label: 'Datalogger Manufacturer',
                //     name: 'dataLoggerManufacturer',
                // },
                {
                    type: 'text',
                    label: 'Datalogger Serial Number',
                    name: 'dataLoggerSerialNumber',
                },
                // {
                //     type: 'text',
                //     label: 'Datalogger Placement',
                //     name: 'dataLoggerPlacement',
                // },
            ],
            admin: [
                {
                    type: 'text',
                    label: 'Collections Contact',
                    name: 'contactCollections',
                },
                {
                    type: 'text',
                    label: 'Facilities Contact',
                    name: 'contactFacilities',
                },
                {
                    type: 'text',
                    label: 'Department/Division',
                    name: 'department',
                },
            ],
            mechanical: [
                {
                    type: 'text',
                    label: 'Air Source/Air Handling Unit (AHU)',
                    name: 'air',
                },
                {
                    type: 'text',
                    label: 'BMS ID',
                    name: 'bms',
                },
                {
                    type: 'text',
                    label: 'Zone',
                    name: 'zone',
                },
            ],
        };
    }
}

/**
 * @class
 * Local form state.
 * @extends {ComposableModule<LocationFormConfig>}
 */
export class LocationFormState extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {LocationFormConfig} config Input configuration.
     */
    constructor(config) {
        super('state', config);
        const { props } = config;

        // MODALS

        /** @type {Vue.Ref<{ isOpen: Boolean }>} Modal open flag. */
        this.photoModal = ref({ isOpen: false });

        /** @type {Vue.Ref<{ isOpen: Boolean }>} Modal open flag. */
        this.floorplanModal = ref({ isOpen: false });

        /** @type {Vue.Ref<{ isOpen: Boolean }>} Modal open flag. */
        this.hierarchyModal = ref({ isOpen: false });

        /** @type {Vue.Ref<{ isOpen: Boolean }>} Modal open flag. */
        this.confirmDeleteModal = ref({ isOpen: false });

        /** @type {Vue.Ref<{ isOpen: Boolean }>} Modal open flag. */
        this.confirmAddModal = ref({ isOpen: false });

        // STATUS

        /** @type {Vue.Ref<Boolean>} Determine if the form currently allows interactions. */
        this.enabled = ref(false);

        /** @type {Vue.Ref<Boolean>} Determine if the form is fetching the location details from the remote. */
        this.refreshing = ref(false);

        /** @type {Vue.Ref<Boolean>} Determine if the form is in edit mode. */
        this.editing = ref(false);

        /** @type {Vue.Ref<Boolean>} Determine if the form is currently saving the location. */
        this.saving = ref(false);

        /** @type {Vue.Ref<Boolean>} Deleting the resource. */
        this.deleting = ref(false);

        /** @type {Vue.Ref<Map<String, Array>>} Collection of form errors, keyed by section name, if any are present. */
        this.errors = ref(
            new Map([
                ['form', []],
                ['hierarchy', []],
            ])
        );

        // FORM DATA

        /**
         * @type {Vue.Ref<{ id: Number, name?: String }>} Target location pointer.
         */
        this.targetLocation = ref(props?.targetLocation?.value ?? null);

        /** @type {Vue.Ref<LocationDetails>} Clean location details. */
        this.cleanLocationDetails = ref(new LocationDetails());

        /** @type {Vue.Ref<LocationDetails>} Dirty location details. */
        this.dirtyLocationDetails = ref(new LocationDetails());

        /** @type {Vue.Ref<number>} */
        this.photoDateTime = ref(new Date().getTime());

        // FORM INPUTS

        /** @type {Map<String, FormKitNode>} Node map. */
        this.nodes = new Map();
    }
}

/**
 * @class
 * Local form state.
 * @extends {ComposableModule<LocationFormConfig>}
 */
class LocationFormProperties extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {LocationFormConfig} config Input configuration.
     */
    constructor(config) {
        super('properties', config);
        const { state } = config;

        /** @type {Vue.ComputedRef<Boolean>} Has errors? */
        this.hasErrors = computed(() => {
            const map = state.errors.value;
            const collection = collect([...map.entries()]);
            const noErrors =
                collection.isEmpty() ||
                collection.every(
                    // ts-ignore
                    ([key, errors]) => !errors || errors.length === 0
                );
            return !noErrors;
        });

        /** @type {Vue.ComputedRef<String[]>} Form errors. */
        this.formErrors = computed(() => {
            const map = state.errors.value;
            const errors = this.hasErrors ? map.get('form') : [];
            return errors;
        });

        /** @type {Vue.ComputedRef<String[]>} Hierarchy errors. */
        this.hierarchyErrors = computed(() => {
            const map = state.errors.value;
            const errors = this.hasErrors ? map.get('hierarchy') : [];
            return errors;
        });

        /** @type {Vue.ComputedRef<{ id: Number, name?: String }>} Location export request details. */
        this.sourceLocation = computed(
            () => state.targetLocation?.value ?? config.constants?.routeLocation
        );

        /** @type {Vue.ComputedRef<Boolean>} Determine if the clean location has been loaded. */
        this.hasCleanLocation = computed(
            () =>
                !!state.cleanLocationDetails.value &&
                state.cleanLocationDetails.value?.resource?.id != null
        );

        /** @type {Vue.ComputedRef<Boolean>} Is enabled? */
        this.isEnabled = computed(() => state.enabled.value);

        /** @type {Vue.ComputedRef<Boolean>} Is disabled? */
        this.isDisabled = computed(() => !state.enabled.value);

        /** @type {Vue.ComputedRef<Boolean>} Is currently refreshing? */
        this.isRefreshing = computed(() => state.refreshing.value);

        /** @type {Vue.ComputedRef<Boolean>} Is currently editing? */
        this.isEditing = computed(() => state.editing.value);

        /** @type {Vue.ComputedRef<Boolean>} Is currently saving? */
        this.isSaving = computed(() => state.saving.value);

        /** @type {Vue.ComputedRef<Boolean>} Is the resource being deleted? */
        this.isDeleting = computed(() => state.deleting.value);

        /** @type {Vue.ComputedRef<Boolean>} Is busy? */
        this.isBusy = computed(
            () =>
                this.isRefreshing.value ||
                this.isDeleting.value ||
                this.isSaving.value
        );
    }
}

/**
 * @class
 * Local form handlers.
 * @extends {ComposableModule<LocationFormConfig>}
 */
class LocationFormHandlers extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {LocationFormConfig} config Input configuration.
     */
    constructor(config) {
        super('handlers', config);
        const { cache, state, props, methods } = config;

        // <!-- INPUT HOOKS -->

        /**
         * Echo input to the console for debugging.
         * @param {any} value Value after debouncing.
         * @param {FormKitNode} node Node firing the input event.
         */
        this.onViewInput = (value, node) => {
            // If node is null, ignore.
            if (!node) {
                return;
            }
            try {
                // Update node reference.
                config.state.nodes.set(node.name, node);
                console.groupCollapsed(
                    `[input::${node.name}] @ ${new Date().toLocaleDateString()}`
                );
                // Ignore form initialization, to avoid overwriting our model.

                // but do register the node to the nodes map.
                if (value?.__init) {
                    console.warn('Initialized field... ignoring.');
                    return;
                }

                console.dir({ value });
            } catch (err) {
                console.error(err);
            } finally {
                console.groupEnd();
            }
        };

        /**
         * Handle FormKit input for a text node.
         * @param {any} value Value after debouncing.
         * @param {FormKitNode} node Node firing the input event.
         */
        this.onTextInput = (value, node) => {
            // If node is null, ignore.
            if (!node) {
                return;
            }
            try {
                // Update node reference.
                config.state.nodes.set(node.name, node);
                console.groupCollapsed(
                    `[input::${node.name}] @ ${new Date().toLocaleDateString()}`
                );
                console.dir({ value });
                // TODO - Handle update of value.
            } catch (err) {
                console.error(err);
            } finally {
                console.groupEnd();
            }
        };

        /**
         * Handle `<input type='file'>` input for the photo or floorplan.
         * @param {{ name: String, file?: File }[] & { __init?: true }} value
         * @param {FormKitNode} node Node firing the input event.
         */
        this.onFileInput = (value, node) => {
            // If node is null, ignore.
            if (!node) {
                return;
            }
            try {
                // Update node reference.
                config.state.nodes.set(node.name, node);
                console.groupCollapsed(
                    `[input::${node.name}] @ ${new Date().toLocaleDateString()}`
                );
                // If init, ignore.
                if (value?.__init) {
                    console.warn('Field initialized... ignoring.');
                    return;
                }
                const details = config.state.dirtyLocationDetails;
                const field = node.name === 'photo' ? 'photo' : 'floorplan';
                const previous = details.value.resource[field];
                const next = value;
                console.dir({ previous, next });
                // @ts-ignore
                details.value.resource[field] =
                    !!value && !!value.length && value.length > 0
                        ? JSON.stringify(value)
                        : [];
            } catch (err) {
                console.error(err);
            } finally {
                console.groupEnd();
            }
        };

        /**
         * Handle `<input type='checkbox'>` input for the photo or floorplan.
         * @param {Boolean & { __init?: true }} value
         * @param {FormKitNode} node Node firing the input event.
         */
        this.onFileRemoveInput = (value, node) => {
            // If node is null, ignore.
            if (!node) {
                return;
            }
            try {
                // Update node reference.
                config.state.nodes.set(node.name, node);
                console.groupCollapsed(
                    `[input::${node.name}] @ ${new Date().toLocaleDateString()}`
                );
                // If init, ignore.
                if (value?.__init) {
                    console.warn('Field initialized... ignoring.');
                    return;
                }
                const details = config.state.dirtyLocationDetails;
                const field =
                    node.name === 'remove-photo' ? 'photo' : 'floorplan';
                const previous = !value;
                const next = value;
                console.dir({ previous, next });

                // Clear the selection input if available.
                const input = document.getElementById(field);
                if (!!input && next === true) {
                    // @ts-ignore
                    input.value = '';
                    details.value[field].value = [];
                }
            } catch (err) {
                console.error(err);
            } finally {
                console.groupEnd();
            }
        };

        /**
         * Handle `<select>` input for the new timezone.
         * @param {String & { __init?: true }} value Timezone value after debouncing.
         * @param {FormKitNode} node Node firing the input event.
         */
        this.onTimezoneInput = (value, node) => {
            // If node is null, ignore.
            if (!node) {
                return;
            }
            try {
                // Update node reference.
                config.state.nodes.set(node.name, node);
                console.groupCollapsed(
                    `[input::${node.name}] @ ${new Date().toLocaleDateString()}`
                );

                // Ignore form initialization, to avoid overwriting our model.
                if (value?.__init) {
                    console.warn('Field initialized... ignoring.');
                    return;
                }

                // Get the details.
                const details = config.state.dirtyLocationDetails;

                // Update the input value.
                const result = details.value.onTimezoneInput(value);
                const isNull =
                    result.next === null ||
                    result.next === '' ||
                    result.next === 'placeholder';

                // If the cleaned input is null, we clear the input element.
                if (isNull) {
                    console.warn('Setting the input to null.');
                    // ts-ignore
                    node._value = null;
                }
            } catch (err) {
                console.error(err);
            } finally {
                console.groupEnd();
            }
        };

        /**
         * Handle `<select>` input for the NARA Standard.
         * @param {String & { __init?: true }} value Timezone value after debouncing.
         * @param {FormKitNode} node Node firing the input event.
         */
        this.onNARAStandardInput = (value, node) => {
            // If node is null, ignore.
            if (!node) {
                return;
            }
            try {
                // Update node reference.
                config.state.nodes.set(node.name, node);
                console.groupCollapsed(
                    `[input::${node.name}] @ ${new Date().toLocaleDateString()}`
                );

                // Ignore form initialization, to avoid overwriting our model.
                if (value?.__init) {
                    console.warn('Field initialized... ignoring.');
                    return;
                }

                // Get the selected NARA Standard based on the selected value.
                const standard =
                    value === null ||
                    value === '' ||
                    value === 'placeholder' ||
                    Number.isNaN(parseInt(value, 10))
                        ? null
                        : value;

                // Get the details.
                const details = config.state.dirtyLocationDetails;

                // Update the input value.
                const result = details.value.onNARAStandardInput(standard);

                // Determine if the input is null.
                const isNull =
                    result.next === null ||
                    result.next === '' ||
                    result.next === 'placeholder';

                // If the cleaned input is null, we clear the input element.
                if (isNull) {
                    console.warn(
                        'Setting the input to the placeholder option.'
                    );
                    config.state.dirtyLocationDetails.value.standard =
                        'placeholder';
                }
            } catch (err) {
                console.error(err);
            } finally {
                console.groupEnd();
            }
        };

        /**
         * On hierarchy edit, show the cascading dropdowns in the modal.
         */
        this.onEditHierarchy = () => {
            methods.openModal(state.hierarchyModal);
        };

        /**
         * On hide modal.
         */
        this.onEditHierarchyCancel = async () => {
            methods.closeModal(state.hierarchyModal);
        };

        /**
         * On hide modal.
         */
        this.onEditHierarchyConfirm = async () => {
            methods.closeModal(state.hierarchyModal);
        };

        /**
         * On photo viewing.
         */
        this.onShowPhoto = () => {
            methods.openModal(state.photoModal);
        };

        /**
         * On hide modal.
         */
        this.onHidePhoto = async () => {
            methods.closeModal(state.photoModal);
        };

        /**
         * On floorplan viewing.
         */
        this.onShowFloorplan = () => {
            methods.openModal(state.floorplanModal);
        };

        /**
         * On hide modal.
         */
        this.onHideFloorplan = async () => {
            methods.closeModal(state.floorplanModal);
        };

        // <!-- LIFECYCLE HOOKS -->

        /**
         * Called when form is initialized.
         */
        this.onInit = async () => {
            methods.setEditing(false);
            await this.onReset();
            await props.onInit(config);
        };

        /**
         * Called when resetting dirty inputs and the form details.
         */
        this.onReset = async () => {
            methods.resetDirtyLocationDetails();
            methods.clearDirtyFileInputs();
            await props.onReset();
        };

        /**
         * Called when exiting the form view.
         */
        this.onExit = async () => {
            methods.setEditing(false);
            state.targetLocation.value = null;
            await props.onExit();
        };

        /**
         * Called when any operation is cancelled.
         */
        this.onCancel = async () => {
            await props.onCancel();
        };

        /**
         * Called when form is refreshed. Requests currentLocation,
         *  which is either an externally set targetLocation or
         *  the current route's location (based on the route URL).
         */
        this.onRefresh = async () => {
            try {
                // Attempts to refresh the form details.
                const target = config.properties.sourceLocation.value;
                await methods.refreshLocationDetails(target);
                await props.onRefresh();
            } catch (err) {
                console.error(err);
                await this.onExit();
            }
        };

        /**
         * Set to editing mode.
         */
        this.onShowEdit = async () => {
            methods.setEditing(true);
            await props.onShowEdit();
        };

        /**
         * Exit out of editing mode.
         */
        this.onEditCancel = async () => {
            methods.setEditing(false);
            methods.resetDirtyLocationDetails(); // Reset the dirty location details.
            await props.onEditCancel();
            await this.onCancel();
        };

        /**
         * Save location details. If successful, calls appropriate callbacks.
         */
        this.onSave = async () => {
            try {
                state.photoDateTime.value = new Date().getTime();
                // Attempts to save and update the location details.
                const target = state.dirtyLocationDetails.value;
                methods.setEditing(false);
                methods.closeFormModals();
                await methods.saveLocationDetails(target);
                await props.onSave();
            } catch (err) {
                console.error(err);
                state.errors.value = state.errors.value.set('form', [
                    err?.message ?? JSON.stringify(err),
                ]);
                methods.setEditing(true);
            }
        };

        /**
         * Set to add mode.
         */
        this.onShowAdd = async () => {
            methods.setEditing(true);
            methods.setCleanLocationDetails(); // Clear the clean location details.
            methods.resetDirtyLocationDetails(); // Reset the dirty location details.
            methods.openModal(state.confirmAddModal);
            await props.onShowAdd();
        };

        /**
         * Exit out of add form.
         */
        this.onAddCancel = async () => {
            methods.closeModal(state.confirmAddModal);
            methods.setCleanLocationDetails(); // Clear the clean location details.
            methods.resetDirtyLocationDetails(); // Reset the dirty location details.
            await props.onAddCancel();
            await this.onCancel();
            await this.onExit();
        };

        /**
         * Create location.
         */
        this.onCreate = async () => {
            try {
                // Attempts to create and save the location details.
                const target = state.dirtyLocationDetails.value;
                methods.setEditing(false);
                methods.closeFormModals();
                await methods.createLocation(target);
                await props.onCreate();
            } catch (err) {
                console.error(err);
                state.errors.value = state.errors.value.set('form', [
                    err?.message ?? JSON.stringify(err),
                ]);
                methods.setEditing(true);
            }
        };

        /**
         * On delete prompt, show the delete modal.
         */
        this.onShowConfirmDelete = async () => {
            methods.openModal(state.confirmDeleteModal);
            await props.onShowConfirmDelete();
        };

        /**
         * On cancel confirm delete modal operation.
         */
        this.onConfirmDeleteCancel = async () => {
            methods.closeModal(state.confirmDeleteModal);
            await props.onConfirmDeleteCancel();
        };

        /**
         * On delete confirmation, delete the resource.
         */
        this.onDelete = async () => {
            try {
                // Attempts to delete the location details.
                const target = config.properties.sourceLocation.value;
                methods.closeFormModals();
                await methods.deleteLocationResource(target);
                await cache.fetch.locations({ ignoreCache: true }); // Refresh the cache.
                // If successful, run the callback.
                await props.onDelete();
            } catch (err) {
                // TODO: Raise alert?
                console.error(err);
                await props.onConfirmDeleteCancel();
            }
        };
    }
}

/**
 * @class
 * Local form methods.
 * @extends {ComposableModule<LocationFormConfig>}
 */
class LocationFormMethods extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {LocationFormConfig} config Input configuration.
     */
    constructor(config) {
        super('methods', config);
        const { cache, state } = config;

        /**
         * Get the hierarchy category names.
         */
        this.getHierarchyTreeLevels = () => {
            const account = getCurrentAccount();
            return account?.treeLabels || ['Site', 'Building', 'Floor', 'Room'];
        };

        /**
         * Set enabled flag.
         * @param {Boolean} value
         */
        this.setEnabled = (value) => {
            state.enabled.value = value;
        };

        /**
         * Set editing mode flag.
         * @param {Boolean} value
         */
        this.setEditing = (value) => {
            state.editing.value = value;
        };

        /**
         * Get the current account from the Vuex store.
         * @returns {AccountResource}
         */
        const getCurrentAccount = () => {
            return cache.api.store.state.accounts.account;
        };

        /**
         * Get the default timezone from the Vuex store.
         * @returns {String}
         */
        const getAccountTimezone = () => {
            return cache.api.store.state.accounts.account.timezone;
        };

        /**
         * Open modal.
         * @param {Vue.Ref<{ isOpen: boolean }>} modalOpenRef
         */
        this.openModal = (modalOpenRef) => {
            modalOpenRef.value = { isOpen: true };
        };

        /**
         * Close modal.
         * @param {Vue.Ref<{ isOpen: boolean }>} modalOpenRef
         */
        this.closeModal = (modalOpenRef) => {
            modalOpenRef.value = { isOpen: false };
        };

        /**
         * Close all modals.
         */
        this.closeFormModals = () => {
            this.closeModal(state.photoModal);
            this.closeModal(state.floorplanModal);
            this.closeModal(state.hierarchyModal);
            this.closeModal(state.confirmDeleteModal);
        };

        /**
         * Refresh location resource with information from the remote.
         * @param {{ id: Number, name?: String }} resource
         */
        this.refreshLocationDetails = async (resource) => {
            try {
                state.refreshing.value = true;
                state.errors.value = state.errors.value.set('form', []);
                console.groupCollapsed(
                    `[refresh::location::details] @ ${new Date().toLocaleDateString()}`
                );

                // Request up-to-date details.
                const response = await cache.fetch.location(resource.id, null);

                // Use remote location resource
                //   to update the clean and dirty
                //   location details references.
                this.setLocationResource(response);
            } catch (err) {
                console.error(err);
                await config.handlers.onExit();
            } finally {
                // Close timer and console.
                state.refreshing.value = false;
                console.groupEnd();
            }
        };

        /**
         * Persist location resource details to the backend.
         * @param {LocationDetails} details Updated details to persist.
         */
        this.saveLocationDetails = async (details) => {
            try {
                state.saving.value = true;
                console.groupCollapsed(
                    `[save::location::details] @ ${new Date().toLocaleDateString()}`
                );

                // Upload location details
                //  this will upload the photo and location
                //  if it is provided and non-conflicting.
                const account = getCurrentAccount();
                const location = details.resource;
                const request = details.getUpdateFormRequest();
                console.log(...request);

                // Send request.
                const response = await locations.updateLocationById(
                    account,
                    location,
                    request
                );

                // Use remote location resource
                //   to update the clean and dirty
                //   location details references.
                this.setLocationResource(response);
            } catch (err) {
                throw err;
            } finally {
                // Close timer and console.
                state.saving.value = false;
                console.groupEnd();
            }
        };

        /**
         * Persist location resource details to the backend.
         * @param {LocationDetails} details Updated details to persist.
         */
        this.createLocation = async (details) => {
            try {
                state.saving.value = true;
                console.groupCollapsed(
                    `[create::location::details] @ ${new Date().toLocaleDateString()}`
                );

                // Upload location details
                //  this will upload the photo and location
                //  if it is provided and non-conflicting.
                const account = getCurrentAccount();
                const request = details.getCreateFormRequest();

                // Send request.
                const response = await locations.createLocation(
                    account,
                    request
                );

                // Use remote location resource
                //   to update the clean and dirty
                //   location details references.
                this.setLocationResource(response);
            } catch (err) {
                throw err;
            } finally {
                // Close timer and console.
                state.saving.value = false;
                console.groupEnd();
            }
        };

        /**
         * Delete current location entirely.
         * @param {Pick<LocationResource, 'id'>} resource
         */
        this.deleteLocationResource = async (resource) => {
            try {
                state.saving.value = true;
                console.groupCollapsed(
                    `[delete::location::details] @ ${new Date().toLocaleDateString()}`
                );

                // Delete location by id.
                const account = getCurrentAccount();
                const location = resource;
                // ts-ignore
                const response = await locations.deleteLocationById(
                    account,
                    location
                );
            } catch (err) {
                console.error(err);
            } finally {
                // Close timer and console.
                state.saving.value = false;
                console.groupEnd();
            }
        };

        /**
         * Overwrite all (clean and dirty) location details
         * @param {LocationResource} resource Location resource.
         */
        this.setLocationResource = (resource) => {
            this.setCleanLocationDetails(LocationDetails.create(resource));
            this.resetDirtyLocationDetails();
        };

        /**
         * Set 'clean' location details.
         * @param {LocationDetails} [details]
         */
        this.setCleanLocationDetails = (details = new LocationDetails()) => {
            state.cleanLocationDetails.value = LocationDetails.clone(details);
        };

        /**
         * Set 'dirty' location details.
         * @param {LocationDetails} [details]
         */
        this.setDirtyLocationDetails = (details = new LocationDetails()) => {
            state.dirtyLocationDetails.value = LocationDetails.clone(details);
        };

        /**
         * Update some properties 'dirty' location details. Shallow.
         * @param {Partial<LocationDetails>} patch
         */
        this.updateDirtyLocationDetails = (patch = {}) => {
            const details = Object.assign(
                {},
                state.dirtyLocationDetails.value,
                patch
            );
            state.dirtyLocationDetails.value = LocationDetails.clone(details);
        };

        /**
         * Reset dirty location details. Useful when cancelling the form.
         */
        this.resetDirtyLocationDetails = () => {
            const details = LocationDetails.clone(
                state.cleanLocationDetails.value
            );
            if (details.resource.timezone === 'placeholder') {
                details.resource.timezone =
                    getAccountTimezone() ?? 'placeholder';
            }
            this.setDirtyLocationDetails(details);
        };

        /**
         * Reset the file inputs. Useful when cancelling the form.
         */
        this.clearDirtyFileInputs = () => {
            // // HACK - Clear the inputs as file selection bugs out when cancelling.
            // const floorplan = document.getElementById('floorplan') ?? {};
            // // ts-ignore
            // floorplan.value = '';

            // const photo = document.getElementById('photo') ?? {};
            // // ts-ignore
            // photo.value = '';

            const floorplan = config.state.nodes.get('floorplan') ?? null;
            if (floorplan) {
                floorplan.input('');
            }
            const photo = config.state.nodes.get('photo') ?? null;
            if (photo) {
                photo.input('');
            }
        };

        /** @returns {Array<{ op: String, path: String, value: any }>} */
        this.calculateDifferences = () => {
            /**
             * Get form data from the resource.
             * @param {Readonly<LocationResource>} resource
             * @returns {Omit<LocationResource, 'entries' | 'parseModel' | 'hierarchy' | 'path' | 'label' | 'photo' | 'floorplan'>}
             */
            const getData = (resource) => {
                return omit(
                    resource,
                    'entries',
                    'parseModel',
                    'hierarchy',
                    'path',
                    'label',
                    'photo',
                    'floorplan'
                );
            };

            // Get comparable data.
            const cleanData = getData(
                state.cleanLocationDetails.value.resource
            );
            const dirtyData = getData(
                state.dirtyLocationDetails.value.resource
            );

            // Find the differences.
            return diff(cleanData, dirtyData, jsonPatchPathConverter);
        };

        // TODO: Turn into computed property.
        this.checkIfDirty = () => {
            // Get the data differences array.
            const differences = this.calculateDifferences();

            const isPhotoRemoved =
                state.dirtyLocationDetails.value.photo.remove === true;
            if (isPhotoRemoved) {
                differences.push({ op: 'remove', path: 'photo', value: null });
            } else {
                differences.push(
                    ...diff(
                        state.cleanLocationDetails.value.photo.value,
                        state.dirtyLocationDetails.value.photo.value,
                        jsonPatchPathConverter
                    )
                );
            }

            const isFloorplanRemoved =
                state.dirtyLocationDetails.value.floorplan.remove === true;
            if (isFloorplanRemoved) {
                differences.push({
                    op: 'remove',
                    path: 'floorplan',
                    value: null,
                });
            } else {
                differences.push(
                    ...diff(
                        state.cleanLocationDetails.value.floorplan.value,
                        state.dirtyLocationDetails.value.floorplan.value,
                        jsonPatchPathConverter
                    )
                );
            }

            const isPathDifferent =
                state.cleanLocationDetails.value.path !=
                state.dirtyLocationDetails.value.path;
            if (isPathDifferent) {
                differences.push({
                    op: 'update',
                    path: 'path',
                    value: state.dirtyLocationDetails.value.path,
                });
            }

            const isStandardDifferent =
                state.cleanLocationDetails.value.standard !=
                state.dirtyLocationDetails.value.standard;
            if (isStandardDifferent) {
                differences.push({
                    op: 'update',
                    path: 'standard',
                    value: state.dirtyLocationDetails.value.standard,
                });
            }

            const hasDifferences = differences.length > 0;
            const isDirty = hasDifferences;
            console.groupCollapsed(`Dirty? [${isDirty}]`);
            console.dir({
                _clean: state.cleanLocationDetails.value,
                _dirty: state.dirtyLocationDetails.value,
                differences,
            });
            console.groupEnd();
            return isDirty;
        };
    }
}

/**
 * @template {Vue.EmitsOptions} E
 * Use Location form state.
 * @param {LocationFormProps} props Location form props.
 * @param {Vue.SetupContext<E>} context Setup context.
 * @returns {LocationFormConfig} Get the composable API.
 */
export const useLocationForm = (props, context) => {
    // Prepare the configuration.
    const config = new LocationFormConfig(props, context);

    // INIT
    config.initializeComposable();

    // EXPOSE
    return config;
};

// <!-- EXPORTS -->
export default {
    useLocationForm,
};
